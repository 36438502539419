import React, { useState } from 'react';
import cn from 'classnames';
import { CollectionKey } from 'settings/collections/types';
import Link from 'next/link';
import Image from 'next/future/image';
import { LeftMenu, MenuItem } from '@/settings/types';
import collections from '@/settings/collections';
import i18n from '@/settings/i18n';
import type { CollectionSummaries } from '@/api/collections/summary';
import arrowRight from '@/public/icons/menu/arrow-right-light.svg';
import { Menu } from '.';

const t = i18n.init();

type MenuClickHandlerProps = {
  handle: string;
  title: string;
  menuLevel: number;
  index: number;
  psku?: string;
};

function DynamicMenuItem({
  handle,
  menuLevel,
  links,
  inStockChildLinks,
  allMenuItems,
  index,
  handleMenuClick,
}: {
  handle: CollectionKey;
  menuLevel: number;
  links: LeftMenu[] | undefined;
  inStockChildLinks: MenuItem[] | null;
  allMenuItems: CollectionSummaries | undefined;
  index: number;
  handleMenuClick: (props: MenuClickHandlerProps) => void;
}) {
  const [show, setShow] = useState(false);

  return (
    <li
      key={handle}
      className={cn(
        'menu-item',
        { relative: menuLevel === 0 },
        { 'hover:bg-gray-200': menuLevel !== 0 }
      )}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Link
        href={
          handle.includes('reebelos-gift-card')
            ? `/${handle}`
            : `/collections/${handle}`
        }
        prefetch={false}
      >
        <a
          className={cn(
            'flex w-full px-3 hover:[text-shadow:0.5px_0px_0px_#000]',
            { 'py-4': menuLevel === 0 },
            { 'py-2 hover:bg-gray-200': menuLevel !== 0 },
            { 'pl-0': menuLevel === 0 && index === 0 }
          )}
          onClick={() =>
            handleMenuClick({
              handle,
              title: collections[handle].title,
              psku: collections[handle].psku,
              menuLevel,
              index,
            })
          }
        >
          <span className="whitespace-nowrap">
            {t(collections[handle].title as string)}
          </span>
          {menuLevel !== 0 &&
            Array.isArray(links) &&
            inStockChildLinks &&
            inStockChildLinks.length > 0 && (
              <div className="float-right ml-auto inline h-[15px] w-[8px] text-blue-500 group-hover:hidden">
                <Image
                  alt={collections[handle].title}
                  src={arrowRight}
                  height="12"
                  width="8"
                />
              </div>
            )}
        </a>
      </Link>

      {Array.isArray(links) && links.length > 0 && show && (
        <Menu
          menuData={inStockChildLinks}
          allMenuItems={allMenuItems}
          menuLevel={menuLevel + 1}
        />
      )}
    </li>
  );
}

export default DynamicMenuItem;
